<template>
    <v-card v-if="valueLocal.length > 0" outlined>
        <v-card-title>Query Parameters</v-card-title>

        <v-row v-for="(parameter, index) in valueLocal" :key="index">
            <v-card-text>
                <v-col class="my-n8">
                    <component
                        :is="parameter.component"
                        v-model="parameter.field"
                        :filter="parameter.filter" />
                </v-col>
            </v-card-text>
        </v-row>
    </v-card>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            default: null
        }
    },
    computed: {
        valueLocal: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
}
</script>
